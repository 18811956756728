import CloseMapOverlay from '../components/CloseMapOverlay';

export default function ErrorPage404() {
  return (
    <div className="flex flex-col w-full h-56 bg-stone-900 text-slate-100 overflow-auto">
      <div className="flex p-4 w-full md:justify-between items-center z-10">
        <div />
        <p className="pt-3 font-sugar text-2xl md:text-5xl">HTTP Error 404</p>
        <CloseMapOverlay className="stroke-white fill-white mt-[-2em]" />
      </div>
      <div className="flex w-full items-center justify-center mt-8">
        <p className="text-lg md:text-xl">Best behave! You may incur the wrath of Gargolon 👀</p>
      </div>
    </div>
  );
}
