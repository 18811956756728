import Link from 'next/link';
import { MdClose } from 'react-icons/md';

export default function CloseMapOverlay({ className, size = '36px' }) {
  return (
    <div className="relative p-0 cursor-pointer z-10">
      <Link href="/" passHref={true}>
        <MdClose className={className} size={size} />
      </Link>
    </div>
  );
}
